import React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1 className="title">
        <Link to={`/`}>
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3 className="title2">
        <Link to={`/`}>
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div>
      <header>{header}</header>
      <main>{children}</main>
      <footer className="footer">
        <div>© {new Date().getFullYear()}, Built with <span role="img" aria-label="heart-break">💔</span></div>
        <div>
          <Link className={location.pathname === '/' ? "link link-active" : "link"} to="/">Home</Link>
          <Link className={location.pathname === '/movies' ? "link link-active" : "link"} to="/movies">Movies</Link>
          <Link className={location.pathname === '/musics' ? "link link-active" : "link"} to="/musics">Musics</Link>
          <span role="img" aria-label="rock">🚀</span>:
          <a href="https://alone.plus">alone.plus</a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
